<template>
  <div>
    <CRow>
      <div class="flex-grow-1 mx-3">
        <CInput :placeholder="PlaceHolder" v-model="text" @input="onChange" @focus="onFocus" @blur="onBlur">
          <template #prepend-content><CIcon name="cil-list-filter"/></template>
        </CInput>
      </div>
      <div v-if="AddBtn" class="mr-3 mb-1">
        <CButton color="primary" @click="$emit('add')">
          <svgicon name="add" width="18px" height="18px" /><span class="ml-1">Add</span>
        </CButton>
      </div>
    </CRow>
    <slot v-bind:text="text"></slot>
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    AddBtn: {type: Boolean, default: false},
    PlaceHolder: {type: String, default: 'Filter'}
  },
  data () {
    return {
      text: null
    }
  },
  methods: {
    onChange: function (val) {
      this.$emit('search', val);
    },
    onFocus: function() {
      this.$emit('focus');
    },
    onBlur: function() {
      this.$emit('blur');
    }
  }
}
</script>
